@import "../../../utils/_variables";

.PortalNav_Wrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 157px;
  min-height: 200px;

  padding: 20px;
  margin-right: 17px;

  border-radius: 10px;
  background-color: $colorSemiGray;
  @media #{$mobileOnly} {
    display: none;
  }

  .PortalNav {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    .PortalNav__Tab_Icon {
      @include center-content;

      position: relative;

      width: 84.6px;
      height: 73.3px;

      cursor: pointer;

      .PortalNav__Tab_Icon_SVG {
        width: 30px;
        height: auto;

        z-index: 100;

        @include transform-scale;
      }

      .PortalNav__Tab_Icon_Splotch {
        left: 0;
        position: absolute;
        height: 100%;
        top: 0;
        width: 100%;
        z-index: 99;
      }
    }

    .PortalNav__Tab_AddQuest {
      @include no-select;
      align-items: center;
      background-color: $colorBackground;
      border-radius: 14px;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      margin-top: 7.5px;
      min-width: 133px;
      min-height: 175px;

      .PortalNav__Tab_AddQuest_Icon {
        align-items: center;
        background-color: $colorGrayOffset;
        border-radius: 50%;
        display: flex;
        height: 70px;
        justify-content: center;
        width: 70px;

        svg {
          width: 30px;
          height: auto;
        }
      }

      .PortalNav__Tab_AddQuest_Text {
        @include no-select;
        color: $colorSoftPurple;
        font-size: 14px;
        font-stretch: normal;
        font-style: normal;
        font-weight: normal;
        line-height: 1.36;
        letter-spacing: normal;
        text-align: center;
      }
    }

    .PortalNav__Tab_UserProfile {
      @include center-content;
      flex-direction: column;

      .PortalNav__Tab_UserProfile_Img {
        width: 70px;
        height: 70px;

        border-radius: 50%;
        object-fit: cover;

        cursor: pointer;
      }

      .PortalNav__Tab_UserProfile_Hello {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.36;
        letter-spacing: normal;
        text-align: center;
        margin: 5px 0px;
      }

      .PortalNav__Tab_UserProfile_Username {
        font-size: 100%;
        font-weight: 900;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.2;
        letter-spacing: 0.3px;
        text-align: center;
      }
    }

    .PortalNav__Tab_Logout {
      display: flex;
      align-items: center;

      svg {
        width: 20px;
        height: auto;
      }

      .PortalNav__Tab_Logout_Text {
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.71;
        letter-spacing: 0.28px;
        text-align: left;
      }
    }
  }

  .PortalNav > * {
    margin: 10px 0;
  }
}
