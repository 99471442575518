$headerHeight: 110px;
$purple: #6144a7;

$footerGreen: #192229;
$darkGreen: #151c22;

$mobileScreen: 768px;
$microScreen: 460px;

$tabletScreen: 1024px;
$desktopScreen: 1200px;
$macroScreen: 1750px;

$compWidth: 16;
$compHeight: 9;

$microOnly: "(max-width: #{$microScreen})";
$mobileOnly: "(max-width: #{$mobileScreen})";
$tabletOnly: "(min-width: #{$mobileScreen + 1}) and (max-width: #{$tabletScreen})";
$desktopTight: "(min-width: #{$tabletScreen + 1}) and (max-width: #{$desktopScreen})";
$mobileAndTabletOnly: "(max-width: #{$tabletScreen})";
$nonDesktop: "(max-width: #{$tabletScreen})";
$tabletAndLarger: "(min-width: #{$mobileScreen + 1})";
$desktopAndLarger: "(min-width: #{$tabletScreen + 1})";
$macroScreens: "(min-width: #{$macroScreen + 1})";

$vwPixelsScale: 1.5;

$colorBlack: #000000;
$colorCyan: #4be0ca;
$colorGray: #83858c;
$colorDarkGray: #313337;
$colorBGray: #212227;
$colorBDarkGray: #18191d;
$colorCGray: #747474;
$colorCDarkGray: #242529;
$colorDGray: #4a4c4d;
$colorDDarkGray: #34363a;
$colorEGray: #777779;
$colorEDarkGray: #302f32;
$colorFGray: #343438;
$colorBlue: #0075e4;
$colorLightBlue: #637a9d;
$colorNavyBlue: #384860;
$colorDarkNavyBlue: #20262f;
$colorSalmon: #ff8e83;
$colorWhite: #fff;
$colorSemiGray: #1f2227;

$colorCandyGreen: #62e841;
$colorCandyGreen2: #44e11d;

$colorCandyRed: #fe5777;
$colorCandyRed2: #e03959;

$colorFacebook: #4267b2;
$colorInstagram: linear-gradient(
  to bottom,
  #405de6,
  #5b51d8,
  #833ab4,
  #c13584,
  #e1306c,
  #fd1d1d,
  #f56040,
  #f77737,
  #fcaf45,
  #ffdc80
);
$colorTwitch: #9147ff;
$colorTwitter: #1da1f2;
$colorYoutube: #ff0000;

$purpleGradient: linear-gradient(to bottom, #3a74e0, #4a6fe6 31%, #5d69ea 67%, #8358ed);

@mixin vw-pixels($styles: (), $mobileScale: 1) {
  @each $p, $v in $styles {
    #{$p}: #{calc($v/$compWidth)}vw;
  }

  @media (min-aspect-ratio: 16/9) {
    @each $p, $v in $styles {
      #{$p}: #{calc($v/$compHeight)}vh;
    }
  }

  @media (max-width: 768px) {
    @each $p, $v in $styles {
      #{$p}: #{calc(($v * $mobileScale) / $compHeight)}vw;
    }
  }
}

@mixin vw-units($styles: (), $mobileScale: 1) {
  @each $p, $v in $styles {
    #{$p}: #{$v}vw;
  }

  @media (min-aspect-ratio: 16/9) {
    @each $p, $v in $styles {
      #{$p}: #{$v * (calc($compWidth/$compHeight))}vh;
    }
  }

  @media (max-width: 768px) {
    @each $p, $v in $styles {
      #{$p}: #{($v * $mobileScale * (calc($compWidth/$compHeight)))}vw;
    }
  }
}

@mixin textShadow() {
  text-shadow: 2px 2px 10px rgb(0, 0, 0);
}

@mixin whiteTextHover($trans: 0.3) {
  transition: #{$trans}s ease-in-out;
  &:hover {
    color: $colorBackground2_light;
  }
}

$colorBackground_light: #fcfcfc;
$colorBackground2_light: #eeeeee;

$colorCrimson: #dc143c;

/* Style Guide v1 */

$colorLightWhite: #f8f8f8;
$colorBackground: #16181c;
$colorBackground2: #444546;
$colorDarkGray: #2e2f32;
$colorLightGray: #83858c;
$colorLight: #ececec;
$colorEnergyBlue: #3a74e0;
$colorLifeGreen: #49e1ca;
$colorFluidPink: #fd8d82;
$colorPurpleRay: #a142ff;
$colorSoftPurple: #e8cdfe;
$colorSoftDarkPurple: #b7bbfd;
$colorDarkPurple: #d1b7e6;
$colorSoftGray: #707070;
$colorDarkGrayOffset: #2b2e38;
$colorLightGrayOffset: #bfbfbf;
$colorGrayOffset: #3c3e42;

.typo_hero {
  font-family: "Roboto";
  font-weight: 700;
  line-height: 80px;
  @include vw-pixels(
    (
      "font-size": 72,
    )
  );
}

.typo_heading_1 {
  font-family: "Roboto";
  font-weight: 700;
  line-height: 60px;
  @include vw-pixels(
    (
      "font-size": 50,
    )
  );
}

.typo_heading_2 {
  font-family: "Roboto";
  font-weight: 700;
  line-height: 40px;
  @include vw-pixels(
    (
      "font-size": 32,
    )
  );
}

.typo_heading_3 {
  font-family: "Roboto";
  font-weight: 700;
  line-height: 32px;
  @include vw-pixels(
    (
      "font-size": 20,
    )
  );
}

.typo_paragraph_big {
  font-family: "Roboto";
  font-weight: 400;
  line-height: 32px;
  @include vw-pixels(
    (
      "font-size": 20,
    )
  );
}

.typo_paragraph {
  font-family: "Roboto";
  font-weight: 400;
  line-height: 24px;
  @include vw-pixels(
    (
      "font-size": 16,
    )
  );
}

.typo_subtitle {
  font-family: "Roboto";
  font-weight: 700;
  line-height: 20px;
  @include vw-pixels(
    (
      "font-size": 12,
    )
  );
}

.typo_small_paragraph {
  font-family: "Roboto";
  font-weight: 700;
  line-height: 20px;
  @include vw-pixels(
    (
      "font-size": 12,
    )
  );
}

/* Style Guide v1 */

// REACT TRANSITION ANIMATIONS

.slide-enter {
  transform: translateY(-100%);
}
.slide-enter.slide-enter-active {
  transform: translateY(0%);
  transition: transform 300ms ease-in-out;
}
.slide-leave {
  transform: translateY(0%);
}
.slide-leave.slide-leave-active {
  transform: translateY(100%);
  transition: transform 300ms ease-in-out;
}

.fadeinout-enter {
  opacity: 0.01;
}

.fadeinout-enter-active {
  opacity: 1;
  transition: all 300ms ease-in;
}

.fadeinout-exit {
  opacity: 1;
}

.fadeinout-exit-active {
  opacity: 0.01;
  transition: all 300ms ease-in;
}

.longfadeinout-enter {
  opacity: 0.01;
}

.longfadeinout-enter-active {
  opacity: 1;
  transition: all 3000ms ease-in;
}

.longfadeinout-exit {
  opacity: 1;
}

.longfadeinout-exit-active {
  opacity: 0.01;
  transition: all 3000ms ease-in;
}

@mixin no-select {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;

  &:focus {
    outline: none;
  }
}

@mixin hoverGlow {
  &:hover {
    box-shadow: 0 0 20px 0 rgba(255, 255, 255, 0.1);
    transition: box-shadow 0.4s ease-in-out;
  }
}

@mixin greyHoverRipple {
  &:hover {
    box-shadow: inset 0 0 24px 30px rgba(255, 255, 255, 0.08);
    transition: box-shadow 0.4s ease-in-out;
  }
}

@mixin transform-scale($scale: 1.1, $duration: 0.2s) {
  &:hover {
    transform: scale($scale);
    transition: transform $duration ease-in-out;
  }

  transition: transform $duration ease-in-out;
}

@mixin purple-gradient-button {
  @include center-content;
  background-image: linear-gradient(
    to bottom,
    #3a74e0,
    #3f72e2 10%,
    #4471e4 20%,
    #496fe6 30%,
    #4f6ee7 40%,
    #546ce8 50%,
    #596ae9 60%,
    #6167ea 70%,
    #6c62eb 80%,
    #775dec 90%,
    #8358ed
  );
  border: none;
  border-radius: 26px;
  color: $colorWhite;
  cursor: pointer;
}

@mixin button-base($borderRadius: 26px) {
  @include center-content;

  border-radius: $borderRadius;
  cursor: pointer;
}

@mixin white-border-button {
  @include button-base();
  border: solid 2px $colorWhite;
  color: $colorWhite;
}

@mixin gray-border-button {
  @include button-base();
  border: solid 2px $colorDGray;
  background-color: $colorDDarkGray;
  color: $colorWhite;
}

@mixin hazy-button {
  @include button-base(12px);
  color: $colorWhite;

  -webkit-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px);
  background-color: rgba(255, 255, 255, 0.25);

  &:hover {
    background-color: rgba(255, 255, 255, 0.4);
  }

  transition: background-color 0.2s ease-in-out;

  @include transform-scale;
}

@mixin hazy-warning {
  @include button-base(12px);
  color: $colorSalmon;

  -webkit-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px);
  background-color: rgba(255, 255, 255, 0.25);
}

@mixin green-key-quest-icon {
  margin-left: 12px;
  min-width: 45px;
  max-width: 45px;
  min-height: 45px;
  max-height: 45px;

  @include center-content;

  background-image: linear-gradient(to bottom, #4be0ca, $colorCandyGreen 127%);
  border-radius: 50%;

  svg {
    width: 26px;
    height: 16px;
  }
}

@mixin purple-quest-icon {
  margin-left: 12px;
  min-width: 45px;
  max-width: 45px;
  min-height: 45px;
  max-height: 45px;

  @include center-content;

  background-image: linear-gradient(to bottom, #e8cdfe, $colorSoftDarkPurple);
  border-radius: 50%;

  svg {
    width: 26px;
    height: 19px;
  }
}

@mixin background-blur-and-border {
  border-radius: 12px;

  -webkit-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px);
  background-color: rgba(255, 255, 255, 0.35);
  // cursor: pointer;

  &:hover {
    background-color: rgba(255, 255, 255, 0.5);
  }

  transition: background-color 0.2s ease-in-out;
}

@mixin scroll-bar-style {
  &::-webkit-scrollbar {
    width: 5px;
    background: rgba(0, 0, 0, 0.2);
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 2px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: rgb(226, 223, 223);
  }
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.2);
}

// START FLEXBOX MIXINS

@mixin center-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin row-around {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

@mixin row-evenly {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

@mixin row-between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

@mixin row-start {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

@mixin row-center {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

@mixin row-end {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

@mixin col-start {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

@mixin col-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@mixin col-end {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

@mixin col-evenly {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

@mixin col-between {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

@mixin col-around {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

// END FLEXBOX MIXINS
